import { H1 } from '../components/Headers'

export function AboutPage() {
  return (
    <div className="main-container page">
      <div className="main-container-child-centered">
        <H1>About</H1>
      </div>
    </div>
  )
}
